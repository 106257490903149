import React, { useEffect, useMemo, useState } from "react";




export default function LoadingSpinner({ open }) {




  return (
    <>
      {open === true ?

        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
        :
        <></>
      }

    </>

  );
}